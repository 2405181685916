<template>
<card-template
  :item="item"
  :selected="selected"
  :compact="compact"
>
  <template #content>
    <div class="property property--status-change">
      <div class="status-change__from">
        Status From
        <span class="status">{{ item.data.from }}</span>
      </div>
      <div class="status-change__to">
        Status To
        <span class="status">{{ item.data.to }}</span>
      </div>
    </div>
  </template>
  <template #content-compact>
    <div class="property property--date">
      {{ item.date }}
    </div>
  </template>
</card-template>
</template>

<script>
import cardTemplate from "./card-base-template.vue";

export default {
  name: 'card-status-change',
  components: {
    cardTemplate,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
    },
    compact: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
</style>