/* Modals */
const alarmModal = () => import("@/components/dashboard-components/checklist/components/alarm-modal.vue");
const emailSequenceModal = () => import("@/components/dashboard-components/checklist/components/email-sequence-modal.vue");

/* Actions Icons */
const alarmIcon = () => import("@/assets/icons/dashboard/actions/alarm.svg");
const forwardIcon = () => import("@/assets/icons/dashboard/actions/forward.svg");
const replyIcon = () => import("@/assets/icons/dashboard/actions/reply.svg");
const replyAllIcon = () => import("@/assets/icons/dashboard/actions/reply-all.svg");
const sentIcon = () => import("@/assets/icons/dashboard/actions/sent.svg");
// const checkIcon = () => import("@/assets/icons/dashboard/actions/check.svg");
const deleteIcon = () => import("@/assets/icons/dashboard/delete.svg");
const editIcon = () => import("@/assets/icons/ico-edit.svg")

/* Other Actions Components */
const dropdown = () => import("@/components/dropdown-base/index.vue");
const checkbox = () => import("@/components/dashboard-components/checklist/components/checkbox.vue");
const attachments = () => import("../components/attachments");
const badge = () => import("../components/badge")
const expandSequenceBadge = () => import("../components/expand-sequence-badge")

export const buttons = {
  'delete': {
    id: 'delete',
    type: 'delete',
    title: 'Delete',
    class: 'hover h-4 2xl:h-5',
    component: deleteIcon,
  },
  'reply': {
    id: 'reply',
    type: 'reply',
    title: 'Reply',
    class: 'hover h-4 2xl:h-5',
    component: replyIcon,
  },
  'reply-all': {
    id: 'reply-all',
    type: 'reply-all',
    title: 'Reply All',
    class: 'hover h-4 2xl:h-5',
    component: replyAllIcon,
  },
  'forward': {
    id: 'forward',
    type: 'forward',
    title: 'Forward',
    class: 'hover h-4 2xl:h-5',
    component: forwardIcon,
  },
  'sent': {
    id: 'sent',
    type: 'sent',
    title: 'Sent',
    class: 'hover h-4 2xl:h-5',
    component: sentIcon,
  },
  'alarm': {
    id: 'alarm',
    type: 'alarm',
    title: 'Alarm',
    class: 'hover h-4 2xl:h-5',
    component: alarmIcon,
    modal: alarmModal,
  },
  'check': {
    id: 'check',
    type: 'check',
    title: '',
    class: 'hover h-4 2xl:h-4',
    component: checkbox,
  },
  'note_select': {
    id: 'note_select',
    type: 'note_select',
    title: 'Select Note',
    component: dropdown,
    props: {
      class: "field h-6 2xl:h-9",
      placeholder: "Select Note",
      colors: {
        bg: '#0D69D5',
      },
      options: ['internal', 'customer'],
    },
  },
  'attachments': {
    id: 'attachments',
    type: 'attachments',
    title: 'Attachments',
    component: attachments,
  },
  'badge': {
    id: 'badge',
    type: 'badge',
    title: 'Type',
    component: badge,
  },
  'edit': {
    id: 'edit',
    type: 'edit',
    class: 'hover h-4 2xl:h-5',
    title: 'Edit',
    component: editIcon,
  },
  'expandSequenceBadge': {
    id: 'expandSequenceBadge',
    type: 'expandSequenceBadge',
    title: 'Expand',
    ref: "sequenceModal",
    component: expandSequenceBadge,
    modal: emailSequenceModal,
    action: 'isSequenceModalOpen',
  }
}